import type { CookieSerializeOptions } from 'cookie';
import type { GetOptions, NuxtCookies } from 'cookie-universal-nuxt';

import { integrationPlugin } from '~/helpers/integrationPlugin';
import { defaultConfig } from '~/modules/core/defaultConfig';
import { mapConfigToSetupObject } from '~/modules/core/helpers';

const moduleOptions = JSON.parse('{"cookies":{"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale","cartCookieName":"vsf-cart","customerCookieName":"vsf-customer","storeCookieName":"vsf-store","messageCookieName":"vsf-message","nostoSessionCookieName":"2c_cId"},"cookiesDefaultOpts":{"httpOnly":false,"secure":true,"sameSite":"Strict","path":"/"},"externalCheckout":{"enable":false,"cmsUrl":"https://www-stage.diptyqueparis.com/","syncUrlPath":"/vue/cart/sync","stores":{"default":false}},"defaultStore":"default","magentoBaseUrl":"https://www-uat.diptyqueparis.com/","imageProvider":"ipx","magentoApiEndpoint":"https://www-stage.diptyqueparis.com/fr_fr/graphql","customApolloHttpLinkOptions":{"useGETForQueries":true},"customer":{"customer_create_account_confirm":false}}');

export default integrationPlugin((plugin) => {
  const cookiesCache = {} as Record<keyof typeof cookieNames, string>;
  const getCookieName = (property: keyof typeof defaultConfig['cookies']): string =>
    moduleOptions.cookies?.[property] ?? defaultConfig.cookies[property];
  const cookieOpts = {
    [defaultConfig.cookies.currencyCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.localeCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.storeCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.customerCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.cartCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.messageCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.countryCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.promoBannerClosedCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    [defaultConfig.cookies.nostoSessionCookieName]: { ...moduleOptions.cookiesDefaultOpts }
  };

  const getCookieOpt = (cookieName?: string): CookieSerializeOptions => cookieOpts[cookieName] ?? {};

  const cookieNames = {
    cart: getCookieName('cartCookieName'),
    customer: getCookieName('customerCookieName'),
    currency: getCookieName('currencyCookieName'),
    store: getCookieName('storeCookieName'),
    locale: getCookieName('localeCookieName'),
    country: getCookieName('countryCookieName'),
    message: getCookieName('messageCookieName'),
    promoBannerClosed: getCookieName('promoBannerClosedCookieName'),
    nostoSession: getCookieName('nostoSessionCookieName')
  };

  const { $cookies } = plugin.app;

  const createCookieOperationsInstance =
    (cookies: NuxtCookies, cache: typeof cookiesCache, storeToCache = true) =>
    <TValue = string>(cookieName: string) => ({
      get: (opts?: GetOptions): TValue => {
        const cookieData = cookies.get(cookieName, opts);
        const data = cookieData || cache[cookieName];

        if (process.client && !cookieData && cache[cookieName]) {
          cookies.set(cookieName, data, { ...getCookieOpt(cookieName), ...opts });
        }

        return data;
      },
      set: (value: TValue, opts?: CookieSerializeOptions) => {
        if (storeToCache) {
          // keep value in both ssr and side for re-usage
          cache[cookieName] = value;
        }

        // set cookies only on browser side
        if (process.client) {
          cookies.set(cookieName, value, { ...getCookieOpt(cookieName), ...opts });
        }
      },
      remove: (opts?: CookieSerializeOptions) => {
        if (cookieName in cache) {
          delete cache[cookieName];
        }

        return cookies.remove(cookieName, opts);
      }
    });

  // Cookieless, "local" cache instance
  const createLocalOperationsInstance =
    <TValue = string>(cache: any) =>
    (cookieName: string) => ({
      get: () => {
        return cache[cookieName];
      },
      set: (value: TValue) => {
        cache[cookieName] = value;
      },
      remove: () => {
        if (cookieName in cache) {
          delete cache[cookieName];
        }

        return true;
      }
    });

  const createCookieOperations = createCookieOperationsInstance($cookies, cookiesCache);
  const createCookieOnlyOperations = createCookieOperationsInstance($cookies, cookiesCache, false);
  const createLocalOperations = createLocalOperationsInstance(cookiesCache);

  // TODO Refactor to separate containers (state.cart.get() .set() .remove()) - this requires a breaking change in api-client types

  const { get: getMessage, set: setMessage, remove: removeMessage } = createLocalOperations(cookieNames.message);

  const { get: getCartId, set: setCartId, remove: removeCartId } = createCookieOperations(cookieNames.cart);
  const { get: getCustomerToken, set: setCustomerToken, remove: removeCustomerToken } = createCookieOnlyOperations(cookieNames.customer);

  const { get: getStore, set: setStore, remove: removeStore } = createCookieOperations(cookieNames.store);
  const { get: getCurrency, set: setCurrency, remove: removeCurrency } = createCookieOperations(cookieNames.currency);
  const { get: getLocale, set: setLocale, remove: removeLocale } = createCookieOperations(cookieNames.locale);
  const { get: getCountry, set: setCountry, remove: removeCountry } = createCookieOperations(cookieNames.country);
  const { get: getNostoSession, set: setNostoSession, remove: removeNostoSession } = createCookieOperations(cookieNames.nostoSession);
  const {
    get: getPromoBannerClosed,
    set: setPromoBannerClosed,
    remove: removePromoBannerClosed
  } = createCookieOperations<boolean>(cookieNames.promoBannerClosed);

  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app,
    additionalProperties: {
      state: {
        getCartId,
        setCartId,
        removeCartId,

        getCustomerToken,
        setCustomerToken,
        removeCustomerToken,

        getStore,
        setStore,
        removeStore,

        getCurrency,
        setCurrency,
        removeCurrency,

        getLocale,
        setLocale,
        removeLocale,

        getCountry,
        setCountry,
        removeCountry,

        getMessage,
        setMessage,
        removeMessage,

        getNostoSession,
        setNostoSession,
        removeNostoSession,

        getPromoBannerClosed,
        setPromoBannerClosed,
        removePromoBannerClosed
      }
    }
  });

  plugin.integration.configure('magento', settings);
});
